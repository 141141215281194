<template>
  <div class="preview-modal-component">
    <div class="pm-component-content">
      <div class="pmc-content-title">
        <span class="pmcc-title-left"></span>
        <span class="pmcc-title-center">账号信息预览</span>
        <span
          class="iconfont iconclose pmcc-title-right"
          @click="previewModal"
        ></span>
      </div>
      <div
        @click="copyTable"
        id="copy"
        class="pmcc-title-copy"
        data-clipboard-target="#pmc-content-input"
      >
        <div class="pmcct-copy-btn">
          <span class="iconfont iconfuzhi pmcctc-btn-icon"></span>
          复制
        </div>
      </div>
      <a-table
        bordered
        :columns="columns"
        :data-source="data"
        :pagination="false"
        class="pmc-content-table"
        id="pmc-content-input"
      ></a-table>
    </div>
    <MessageComponent :visible="visible" />
  </div>
</template>
<script>
import ClipboardJS from "clipboard";
import Iconfont from "./../Iconfont";
import MessageComponent from "./../MessageComponent";
const columns = [
  {
    title: "类别",
    dataIndex: "type",
    width: 150
  },
  {
    title: "内容",
    dataIndex: "content"
  }
];
const customData = [
  {
    key: "合同号",
    value: "合同号"
  },
  {
    key: "开题单号",
    value: "开题单号"
  },
  {
    key: "提取码",
    value: "提取码"
  },
  {
    key: "AccessKeyId",
    value: "AccessKeyId"
  },

  {
    key: "AccessKeySecret",
    value: "AccessKeySecret"
  },
  {
    key: "区域",
    value: "区域"
  },
  {
    key: "预设OSS路径",
    value: "预设OSS路径"
  },
  {
    key: "过期时间",
    value: "过期时间"
  }
];
export default {
  props: {
    preview: {
      type: Object,
      default: {}
    },
    previewModal: {
      type: Function,
      default: function () {}
    }
  },
  data() {
    return {
      columns,
      data: [],
      visible: false
    };
  },
  mounted() {
    customData.forEach((item) => {
      this.data.push({
        key: item.key,
        type: item.value,
        content: this.preview[item.key]
      });
    });
  },
  components: {
    Iconfont,
    MessageComponent
  },
  methods: {
    copyTable() {
      this.visible = false;
      var clipboard = new ClipboardJS("#copy");
      clipboard.on("success", (e) => {
        // console.info("Action:", e.action);
        // console.info("Text:", e.text);
        // console.info("Trigger:", e.trigger);
        this.visible = true;
        e.clearSelection();
      });

      clipboard.on("error", (e) => {
        // console.error("Action:", e.action);
        // console.error("Trigger:", e.trigger);
      });
    }
  }
};
</script>
<style lang="less">
@import "./../../style/previewModal.less";
</style>
